<template>
  <div class="balance-slots">
    <div class="balance-text">
      <i class="el-icon-warning" style="color: #db6d39; margin-right: 12px" />
      На балансе доступно: <b> {{ countLeft }} тайм слотов </b>
    </div>
    <span class="balance-btn">
      <iq-button size="small" @onClick="$emit('addBalance')">
        Пополнить баланс
      </iq-button>
    </span>
  </div>
</template>

<script>
import IqButton from '@/UI/buttons/IqButton'

export default {
  name: 'BalanceLeft',
  components: {
    IqButton,
  },
  props: {
    countLeft: {
      type: Number,
      required: true,
    },
  },
}
</script>

<style lang="sass">
$mainFont: #606266
$mainBg: #FDF6EC
$borderBg: #FAECD8

.balance-text
  display: inline-block
  text-align: left
  line-height: 22px
  font-weight: normal
  font-size: 14px
  width: 40%
  padding: 0 !important
  color: $mainFont
  @media (max-width: 550px)
    width: 100%
.balance-btn
  width: 160px
  display: block
  @media (max-width: 550px)
    width: 100%
    margin-top: 16px

.balance-slots
  display: flex
  align-items: center
  justify-content: space-between
  padding: 8px 15px
  background: $mainBg
  border: 1px solid $borderBg
  border-radius: 4px
  margin-bottom: 20px
  @media (max-width: 550px)
    flex-direction: column
</style>
